<template>
  <el-header class="navHeader">
    <el-row type="flex" justify="start" class="headerContent">
      <el-col :span="4" class="headerLogo">
        <div  @click="gotoHome">
          <img src="../assets/image/version1/logo.png" class="headerLogoImage" alt="">
        </div>
      </el-col>
      <el-col :span="16" class="headerRight">
        <el-menu router :default-active="this.$router.currentRoute.path" mode="horizontal">
          <el-menu-item v-for="(item, i) in list.menu" :key="i" :index="item.path" >{{item.name}}</el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="4"></el-col>
    </el-row>
  </el-header>
</template>

<script>
export default {
  name: "navHeader",
  data() {
    return {
      list: {
        menu: [{name:"首页", path:"/index"}, {name:"服务项目", path:"/service"}, {name:"政策资讯", path:"/allZc"}, {name:"申报中心", path:"/declare"}, {name:"关于我们", path:"/about"}],
      },
    };
  },
  created() {},
  methods: {
    gotoHome() {
      this.$router.push({path: "/index"});
    },
  },
};
</script>

<style scoped lang="scss">
.navHeader {
  user-select: none;
  text-align: center;

  .headerContent {
    padding-top: vwf(10);
  }
  .headerLogo {
    cursor: pointer;
    display: flex;
    justify-content: right;
  }
  .headerLogoImage {
    width: vwf(60);
    height: vwf(60);
  }
  .headerRight {
    cursor: pointer;
    display: flex;
    justify-content: right;
  }
}

// 覆盖el的样式
.el-header {
  padding: vwf(0) vwf(0);
  height: vwf(70) !important;
}
.el-menu.el-menu--horizontal {
  border-bottom: none;
}
.el-menu--horizontal>.el-menu-item {
  font-size: vwf(16);
  color: #999999;
  height: vwf(60);
  line-height: vwf(60);
  padding: vwf(0) vwf(5);
  margin: vwf(0) vwf(15);
}
.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 2px solid #333333;
  color: #333333;
}
</style>
